import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './HeaderExpanded.scss';
import '../HomepageFrames/Frame.scss';

function HeaderExpanded(props) {
  const { image, title } = props;

  var backgroundImageStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center right',
    backgroundRepeat: 'no-repeat'
  };

  const Header = (
    <Container>
      <Row noGutters>
        <Col xs={10} md={6} className='hp-title hp-title--subtitle px-md-3'>
          <header className='hp-nav-mobile text-light hp-mobile-title'>{title}</header>
        </Col>
      </Row>
    </Container>
  );

  return image ? (
    <Container
      fluid
      className='px-0 hp-page--blue-dark hp-expanded-header d-flex align-items-center'
    >
      <div style={backgroundImageStyle} className='hp-expanded-header-image' />
      {Header}
    </Container>
  ) : (
    <Container
      fluid
      className='px-0 hp-page--blue-grey hp-expanded-header--no-image d-flex align-items-center'
    >
      {Header}
    </Container>
  );
}

export default HeaderExpanded;
