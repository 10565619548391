import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'gatsby';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import '../styles/australia.scss';
import { Helmet } from 'react-helmet';

function Australia() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Australian Digital Grower Tools</title>
        <meta
          name='description'
          content='HortPlus has built and provided tools to the Australian market
          including digital grower tools'
        />
      </Helmet>
      <HeaderExpanded
        title='Australian Digital Grower Tools'
        image={
          process.env.GATSBY_S3_BUCKET_ASSETS +
          'images/australian_grower_tools.jpg'
        }
      />
      <Container className='hp-padding-100 mb-5 text-center'>
        <p>
          HortPlus has built and provided tools to the Australian market
          including digital grower tools.
        </p>
        <Button as={Link} to={'/contact'}>
          Contact us
        </Button>
      </Container>
    </>
  );
}

export default Australia;
